import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  NotificationBadge,
} from '../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
import NotificationsIcon from '@material-ui/icons/Notifications';

import css from './TopbarDesktop.module.css';
import { Tooltip } from '@material-ui/core';

var propsRef = null;

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    getNotification,
    notifications,
    updateMetadata
  } = props;
  propsRef = props;
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  let loadNotifications = [];

  if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
    loadNotifications = localStorage.getItem('Notifications') ? JSON.parse(localStorage.getItem('Notifications')) : [];
  }
  // const [notifications, setNotifications] = useState(loadNotifications);

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const currentUserType = currentUser?.attributes?.profile?.protectedData?.userType;
  const isUserSupplier = (currentUserType == 'supplier' || currentUserType == 'both');
  const isUserCustomer = (currentUserType == 'customer' || currentUserType == 'both');
  const isUserOnlySupplier = (currentUserType == 'supplier');
  const isUserOnlyCustomer = (currentUserType == 'customer');
  const currentUserId = currentUser?.id?.uuid;
  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const rentalManagerLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="SaleRentalManagerPage"
      params={{ tab: 'allBookings' }}
      onClick={() => localStorage.setItem('BookingOverviewActiveTab', 'allBookings')}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.rentalManager" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const rentalsLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="OrderRentalManagerPage"
      params={{ tab: 'allBookings' }}
      onClick={() => localStorage.setItem('BookingOverviewActiveTab', 'allBookings')}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.rentals" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        {/* <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem> */}
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const clearNotification = (transactionId, txRole, lastTransition) => {
    const currentUserId = propsRef.currentUser?.id?.uuid;
    propsRef.updateMetadata(transactionId, txRole, lastTransition, currentUserId).then(res => {
    });
  }

  const notifyCount = notifications.filter(x=>x.isActive != true).length;

   const notificationBell = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <NotificationsIcon />
        {notifications != null && notifyCount > 0 ? <NotificationBadge className={css.bellNotification} count={notifyCount} /> : null}
      </MenuLabel>
      <MenuContent className={css.profileMenuContent} style={{ maxHeight: '55vh', overflow: 'auto' }}>

        {notifications?.map((item, index) => {
          return (
            <MenuItem key="ProfileSettingsPage">
              <NamedLink
                className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
                name={item.isInvoice ? (item.isSales ? "SaleRentalManagerPageWithId" : "OrderRentalManagerPage") : (item.isSales ? "SaleDetailsPage" : "OrderDetailsPage")}
                params={item.isInvoice ? { tab: 'allinvoice', id: item?.transactionId } : { id: item?.transactionId || '' }}
              >
                <div className={css.messageSection} onClick={() => clearNotification(item.transactionId, item.txRole, item.lastTransition)}>
                  <span className={css.menuItemBorder} />
                  <div className={css.notificationAvatar}>
                    <Avatar className={css.avatar} user={item} disableProfileLink />
                  </div>
                  <div className={css.messageContent}>
                    <div className={css.userSectionNot}>
                        <p className={css.senderName}>{item.senderName} </p> 
                        {!item.isActive ? <div className={css.chatNotification}></div> : null }
                    </div>
                    <p className={css.message}>{item.content.length > 45 && item.type == 'message' ? item.content.slice(0, 45) : item.content}
                      {item.content.length > 45 && item.type == 'message' ? <a>..Read more</a> : null}</p>
                  </div>
                </div>
                {notifications.length != index + 1 ? <hr className={css.messageLine} /> : null}
              </NamedLink>
            </MenuItem>
          );
        }, props)}

      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  // const [count, setCount] = useState(0);
  // useEffect(() => {
  //   //Implementing the setInterval method
  //   const interval = setInterval(() => {
  //     setCount(count + 1);
  //     getNotification(currentUserId);
  //   }, 10000);

  //   //Clearing the interval
  //   return () => clearInterval(interval);
  // }, [count]);

  // {
  //   useEffect(() => {
  //     //Implementing the setInterval method
  //     getNotification(currentUserId);
  //   }, []);
  // }



  return (
    <nav className={classes}>
      <LinkedLogo
        className={css.logoLink}
        format="desktop"
        alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
      />
      {search}
      {isUserSupplier ?
        <NamedLink className={css.createListingLink} name="NewListingPage">
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.createListing" />
          </span>
        </NamedLink>
        : null}
      {inboxLink}
      {isUserSupplier ? rentalManagerLink : null}
      {isUserCustomer ? rentalsLink : null}
      {isUserCustomer || isUserSupplier ? (notifications?.length > 0 ?
        notificationBell
        : <div className={css.bellWithNoNotification}>
          <Tooltip title='No notifications'>
            <NotificationsIcon />
          </Tooltip>
        </div> ) : null}
      {profileMenu}
      {signupLink}
      {loginLink}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
  updateMetadata: func.isRequired
};

export default TopbarDesktop;
