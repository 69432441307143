import { fetchCurrentUser } from '../../ducks/user.duck';
import { setInitialValues as setInitialValuesForPaymentMethods } from '../../ducks/invoice.duck';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { inviteCustomerMail } from '../../util/api';
import { getAllTransitionsForEveryProcess } from '../../transactions/transaction';
import { transitions } from '../../transactions/transactionProcessBooking';

// ================ Action types ================ //

export const SETUP_INTENT_REQUEST = 'app/CustomersPage/SETUP_INTENT_REQUEST';
export const SETUP_INTENT_SUCCESS = 'app/CustomersPage/SETUP_INTENT_SUCCESS';
export const SETUP_INTENT_ERROR = 'app/CustomersPage/SETUP_INTENT_ERROR';

export const STRIPE_CUSTOMER_REQUEST = 'app/CustomersPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/CustomersPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/CustomersPage/STRIPE_CUSTOMER_ERROR';



// ================ Reducer ================ //

const initialState = {
  setupIntentInProgress: false,
  setupIntentError: null,
  setupIntent: null,
  stripeCustomerFetched: false,
};

export default function payoutMethodsPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SETUP_INTENT_REQUEST:
      return { ...state, setupIntentInProgress: true, setupIntentError: null };
    case SETUP_INTENT_SUCCESS:
      return {
        ...state,
        setupIntentInProgress: false,
        setupIntentError: null,
        setupIntent: payload,
      };
    case SETUP_INTENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, setupIntentInProgress: false, setupIntentError: null };
    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };
    case SEND_CUSTOMER_INVITE_REQUEST:
      return { ...state,  };
    case SEND_CUSTOMER_INVITE_SUCCESS:
      return { ...state,  };
    case SEND_CUSTOMER_INVITE_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setupIntentRequest = () => ({ type: SETUP_INTENT_REQUEST });
export const setupIntentSuccess = () => ({ type: SETUP_INTENT_SUCCESS });
export const setupIntentError = e => ({
  type: SETUP_INTENT_ERROR,
  error: true,
  payload: e,
});

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = e => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

export const sendCustomerInviteRequest = () => ({ type: SEND_CUSTOMER_INVITE_REQUEST });
export const sendCustomerInviteSuccess = () => ({ type: SEND_CUSTOMER_INVITE_SUCCESS });
export const sendCustomerInviteError = e => ({
  type: SEND_CUSTOMER_INVITE_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const createStripeSetupIntent = () => (dispatch, getState, sdk) => {
  dispatch(setupIntentRequest());
  return sdk.stripeSetupIntents
    .create()
    .then(response => {
      const setupIntent = response.data.data;
      dispatch(setupIntentSuccess(setupIntent));
      return setupIntent;
    })
    .catch(e => {
      const error = storableError(e);
      log.error(error, 'create-setup-intent-failed');
      dispatch(setupIntentError(error));
      return { createStripeSetupIntentSuccess: false };
    });
};

export const stripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }))
    .then(response => {
      dispatch(stripeCustomerSuccess());
    })
    .catch(e => {
      const error = storableError(e);
      log.error(error, 'fetch-stripe-customer-failed');
      dispatch(stripeCustomerError(error));
    });
};

const getInvoiceTranstionName = (tab) => {
  switch (tab) {
    case "invoice":
      return [transitions.COMPLETE, transitions.ADMIN_COMPLETE, transitions.SENDINVOICE, transitions.CONFIRM_PAYMENT, transitions.EDIT_INVOICE];
    case "allinvoice":
      return [transitions.COMPLETE,transitions.ADMIN_COMPLETE, transitions.SENDINVOICE, transitions.CONFIRM_PAYMENT,transitions.EDIT_INVOICE];
    case "pendinginvoice":
      return [transitions.COMPLETE, transitions.ADMIN_COMPLETE];
    case "sentinvoice":
      return [transitions.SENDINVOICE,transitions.EDIT_INVOICE];
    case "paidinvoice":
      return transitions.CONFIRM_PAYMENT;
    default:
      break;
  }
}

export const loadData = () => (dispatch, getState, sdk) => {
  dispatch(setInitialValuesForPaymentMethods());
  const onlyFilterValues = {
    orders: 'order',
    sales: 'sale',
  };

  let onlyFilter = onlyFilterValues[tab];

  var lastTransitions = getAllTransitionsForEveryProcess();
  dispatch(fetchOrdersOrSalesRequest());

  const { page = 1 } = parse(search);

  let apiQueryParams = {
    only: onlyFilter,
     lastTransitions: lastTransitions,
    include: [
      'listing',
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'booking',
      'email'
    ],
    'fields.transaction': [
      'processName',
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'lineItems',
      'protectedData'
    ],
    'fields.listing': ['title', 'availabilityPlan', 'publicData.listingType'],
    'fields.user': ['email', 'protectedData.phoneNumber', 'profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    perPage: 25,
  };

  // if (tab == 'invoice' || tab == 'allinvoice' || tab == 'pendinginvoice' || tab == 'sentinvoice' || tab == 'paidinvoice') {

  //   delete apiQueryParams.only;
  // }
  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      let { tab } = params;
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrdersOrSalesSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};

export const sendCustomerInvite = (email) => {

  return inviteCustomerMail(email).then(res => {

  }).catch(e => {

    throw e;
  });
};

const INBOX_PAGE_SIZE = 5;


