import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_DRIVER_FEE } from '../../util/types';
import { propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemDriverFeeMaybe = props => {
  const {
    lineItems,
    code,
    intl,
    applyDiscountValue } = props;
  const driverFeeLineItem = lineItems.find(
    item => item.code === LINE_ITEM_DRIVER_FEE
  );

  return driverFeeLineItem ? (

    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="OrderBreakdown.driverFee" />
      </span>
      <span className={css.itemValue}>{formatMoney(intl, driverFeeLineItem.lineTotal)}  </span>

    </div>
  ) : null
};

LineItemDriverFeeMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  code: propTypes.lineItemUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemDriverFeeMaybe;
