import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_DISOUNT_PERCENT } from '../../util/types';
import { propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemDiscountPercentMaybe = props => {
  const { 
    lineItems,
    code, 
    intl, 
    applyDiscountValue } = props;

  const isDiscountApplied = lineItems.find(
    item => item.code === LINE_ITEM_DISOUNT_PERCENT 
  );
  const DiscountValue = isDiscountApplied?applyDiscountValue?.toFixed(2):0;

  return isDiscountApplied ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="OrderBreakdown.discountApplied"  />
      </span>
      <span className={css.itemValue}>{DiscountValue} % </span>
      
    </div>
  ) : null;
};

LineItemDiscountPercentMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  code: propTypes.lineItemUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemDiscountPercentMaybe;
