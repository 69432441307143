import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { getAllTransitionsForEveryProcess } from '../../transactions/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { transitions } from '../../transactions/transactionProcessBooking';
import { inviteBookingLink, updatePrivileged, updateSeenMetadata } from '../../util/api';

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/RentalManagePage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/RentalManagePage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/RentalManagePage/FETCH_ORDERS_OR_SALES_ERROR';

export const UPDATE_PRIVILAGED_REQUEST = 'app/RentalManagePage/UPDATE_PRIVILAGED_REQUEST';
export const UPDATE_PRIVILAGED_SUCCESS = 'app/RentalManagePage/UPDATE_PRIVILAGED_SUCCESS';
export const UPDATE_PRIVILAGED_ERROR = 'app/RentalManagePage/UPDATE_PRIVILAGED_ERROR';

export const GET_OWN_LISTINGS_REQUEST = 'app/RentalManagePage/GET_OWN_LISTINGS_REQUEST';
export const GET_OWN_LISTINGS_SUCCESS = 'app/RentalManagePage/GET_OWN_LISTINGS_SUCCESS';
export const GET_OWN_LISTINGS_ERROR = 'app/RentalManagePage/GET_OWN_LISTINGS_ERROR';

export const UPDATE_METADATA_REQUEST = 'app/RentalManagePage/UPDATE_METADATA_REQUEST';
export const UPDATE_METADATA_SUCCESS = 'app/RentalManagePage/UPDATE_METADATA_SUCCESS';
export const UPDATE_METADATA_ERROR = 'app/RentalManagePage/UPDATE_METADATA_ERROR';

export const SEND_BOOKING_INVITE_REQUEST = 'app/RentalManagePage/SEND_BOOKING_INVITE_REQUEST';
export const SEND_BOOKING_INVITE_SUCCESS = 'app/RentalManagePage/SEND_BOOKING_INVITE_SUCCESS';
export const SEND_BOOKING_INVITE_ERROR = 'app/RentalManagePage/SEND_BOOKING_INVITE_ERROR';


// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
  rentalFetchInProgress: false,
  link : null,
};

export default function rentalManagerPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, rentalFetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        rentalFetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload, rentalFetchInProgress: false };

    case UPDATE_PRIVILAGED_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case UPDATE_PRIVILAGED_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case UPDATE_PRIVILAGED_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };
    case GET_OWN_LISTINGS_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case GET_OWN_LISTINGS_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case GET_OWN_LISTINGS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    case UPDATE_METADATA_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case UPDATE_METADATA_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case UPDATE_METADATA_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

      case SEND_BOOKING_INVITE_REQUEST:
        return { ...state};
      case SEND_BOOKING_INVITE_SUCCESS: {
        return {
          ...state,link: payload
        };
      }
      case SEND_BOOKING_INVITE_ERROR:
        console.error(payload); 
        return { ...state };
  
    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});



const updatePrivilegedTransactionRequest = () => ({ type: UPDATE_PRIVILAGED_REQUEST });
const updatePrivilegedTransactionSuccess = (response, dispatch, getstate, sdk) => ({
  type: UPDATE_PRIVILAGED_SUCCESS,
  payload: response,
});
const updatePrivilegedTransactionError = e => ({
  type: UPDATE_PRIVILAGED_ERROR,
  error: true,
  payload: e,
});

const getOwnListingsRequest = () => ({ type: GET_OWN_LISTINGS_REQUEST });
const getOwnListingsSuccess = (response, dispatch, getstate, sdk) => ({
  type: GET_OWN_LISTINGS_SUCCESS,
  payload: response,
});
const getOwnListingsError = e => ({
  type: GET_OWN_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const updateMetadataRequest = () => ({ type: UPDATE_METADATA_REQUEST });
const updateMetadataSuccess = (response, dispatch, getstate, sdk) => ({
  type: UPDATE_METADATA_SUCCESS,
  payload: response,
});
const updateMetadataError = e => ({
  type: UPDATE_METADATA_ERROR,
  error: true,
  payload: e,
});

export const sendBookingInviteRequest = () => ({ type: SEND_BOOKING_INVITE_REQUEST });
export const sendBookingInviteSuccess = (response) => ({ type: SEND_BOOKING_INVITE_SUCCESS, 
                                                    payload: response,
 });
export const sendBookingInviteError = e => ({
  type: SEND_BOOKING_INVITE_ERROR,
  error: true,
  payload: e,
});



// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 5;

export const loadData = (params, search) => (dispatch, getState, sdk) => {

  const isRentals = location.pathname.includes('order/');

  let { tab } = params;

  const onlyFilterValues = {
    orders: 'order',
    sales: 'sale',
  };

  let onlyFilter = onlyFilterValues[tab];

  var lastTransitions = getAllTransitionsForEveryProcess();
  if (tab == 'invoice'
    || tab == 'allinvoice'
    || tab == 'pendinginvoice'
    || tab == 'sentinvoice'
    || tab == 'paidinvoice'
    || tab == 'sales'
    || tab == 'customers'
    || tab == 'allBookings'
    || tab == 'bookingRequests'
    || tab == 'acceptedBookings'
    || tab == 'rentingBookings'
    || tab == 'paidBookings'
    || tab == 'declinedBookings'
    || tab == 'equipmentSchedule'
    || tab == 'equipmentRental'
  ) {

    if (tab == 'customers') {
      lastTransitions = "transition/confirm-manual-payment";
    }
    else {
      lastTransitions = getInvoiceTranstionName(tab, isRentals);
    }
    onlyFilter = "sale";
  }
  else if (!onlyFilter) {
    return Promise.reject(new Error(`Invalid tab for RentalManagerPage: ${tab}`));
  }
  dispatch(fetchOrdersOrSalesRequest());

  const { page = 1 } = parse(search);

  let apiQueryParams = {
    only: onlyFilter,
    lastTransitions: lastTransitions,
    include: [
      'listing',
      'listing.images',
      'provider',
      'provider.profileImage',
      'customer',
      'customer.attributes.profile.email',
      'customer.profileImage',
      'booking',
      'email'
    ],
    'fields.transaction': [
      'processName',
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'lineItems',
      'protectedData',
      'metadata',
    ],
    'fields.listing': ['title', 'availabilityPlan', 'publicData', 'availability', 'timeSlots', 'availabilityPlan.entries', 'metadata', 'createdAt'],
    // 'fields.user': ['email', 'protectedData.phoneNumber', 'profile.displayName', 'profile.abbreviatedName', 'metadata'],
    'fields.image': [
      'variants.scaled-small',
      'variants.square-small2x',
      'variants.scaled-medium',
      `variants.listing-card`,
      `variants.listing-card-2x`,
    ],
    page,
    // perPage: tab == 'sales' || tab == 'customers' ?  null : INBOX_PAGE_SIZE,
    perPage: null,
  };

  // if (tab == 'invoice' || tab == 'allinvoice' || tab == 'pendinginvoice' || tab == 'sentinvoice' || tab == 'paidinvoice' || tab == 'bookingOverview' || tab == 'bookingRequests' || tab == 'paidBookings') {

  //   delete apiQueryParams.only;
  // }

  if (isRentals) {
    apiQueryParams.only = 'order';
  } else {
    apiQueryParams.only = 'sale';
  }

  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      let { tab } = params;
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrdersOrSalesSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};

const getInvoiceTranstionName = (tab, isRentals) => {

  switch (tab) {
    case "invoice":
      if (isRentals) {
        return [transitions.SENDINVOICE, transitions.CONFIRM_PAYMENT, transitions.EDIT_INVOICE];
      }
      return [
        transitions.COMPLETE,
        transitions.ADMIN_COMPLETE,
        transitions.SENDINVOICE,
        transitions.CONFIRM_PAYMENT,
        transitions.EDIT_INVOICE,
      ];
    case "allinvoice":
      if (isRentals) {
        return [
          transitions.SENDINVOICE,
          transitions.CONFIRM_PAYMENT,
          transitions.EDIT_INVOICE,
          transitions.CONFIRM_PAYMENT,
          transitions.CANCEL,
          transitions.REVIEW_1_BY_PROVIDER,
          transitions.REVIEW_2_BY_PROVIDER,
          transitions.REVIEW_1_BY_CUSTOMER,
          transitions.REVIEW_2_BY_CUSTOMER,
          transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD,
          transitions.EXPIRE_PROVIDER_REVIEW_PERIOD,
          transitions.EXPIRE_REVIEW_PERIOD,
        ];
      }
      return [
        transitions.COMPLETE,
        transitions.ADMIN_COMPLETE,
        transitions.SENDINVOICE,
        transitions.CONFIRM_PAYMENT,
        transitions.EDIT_INVOICE,
        transitions.CONFIRM_PAYMENT,
        transitions.CANCEL,
        transitions.REVIEW_1_BY_PROVIDER,
        transitions.REVIEW_2_BY_PROVIDER,
        transitions.REVIEW_1_BY_CUSTOMER,
        transitions.REVIEW_2_BY_CUSTOMER,
        transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD,
        transitions.EXPIRE_PROVIDER_REVIEW_PERIOD,
        transitions.EXPIRE_REVIEW_PERIOD,
      ];
    case "pendinginvoice":
      if (isRentals) {
        return [transitions.SENDINVOICE, transitions.EDIT_INVOICE];
      }
      return [transitions.COMPLETE, transitions.ADMIN_COMPLETE];
    case "sentinvoice":
      return [transitions.SENDINVOICE, transitions.EDIT_INVOICE];
    case "paidinvoice":
      return [
        transitions.CONFIRM_PAYMENT,
        transitions.CANCEL,
        transitions.REVIEW_1_BY_PROVIDER,
        transitions.REVIEW_2_BY_PROVIDER,
        transitions.REVIEW_1_BY_CUSTOMER,
        transitions.REVIEW_2_BY_CUSTOMER,
        transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD,
        transitions.EXPIRE_PROVIDER_REVIEW_PERIOD,
        transitions.EXPIRE_REVIEW_PERIOD,
      ];
    case "bookingRequests":
      return [transitions.REQUEST_PAYMENT, transitions.REQUEST_PAYMENT_AFTER_INQUIRY];
    case "allBookings":
      return [
        transitions.ACCEPT,
        transitions.DECLINE,
        transitions.COMPLETE,
        transitions.ADMIN_COMPLETE,
        transitions.SENDINVOICE,
        transitions.EDIT_INVOICE,
        transitions.REQUEST_PAYMENT,
        transitions.REQUEST_PAYMENT_AFTER_INQUIRY,
        transitions.CONFIRM_PAYMENT,
        transitions.CANCEL,
        transitions.REVIEW_1_BY_PROVIDER,
        transitions.REVIEW_2_BY_PROVIDER,
        transitions.REVIEW_1_BY_CUSTOMER,
        transitions.REVIEW_2_BY_CUSTOMER,
        transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD,
        transitions.EXPIRE_PROVIDER_REVIEW_PERIOD,
        transitions.EXPIRE_REVIEW_PERIOD,
      ];
    case "acceptedBookings":
      return [
        transitions.ACCEPT,
        transitions.COMPLETE,
        transitions.ADMIN_COMPLETE,
        transitions.SENDINVOICE,
        transitions.EDIT_INVOICE];
    case "rentingBookings":
      return [transitions.ACCEPT];
    case "declinedBookings":
      return [transitions.DECLINE];
    case "paidBookings":
      return [
        transitions.CONFIRM_PAYMENT,
        transitions.CANCEL,
        transitions.REVIEW_1_BY_PROVIDER,
        transitions.REVIEW_2_BY_PROVIDER,
        transitions.REVIEW_1_BY_CUSTOMER,
        transitions.REVIEW_2_BY_CUSTOMER,
        transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD,
        transitions.EXPIRE_PROVIDER_REVIEW_PERIOD,
        transitions.EXPIRE_REVIEW_PERIOD,
      ];
    case "sales":
      return [
        transitions.CONFIRM_PAYMENT,
        transitions.CANCEL,
        transitions.REVIEW_1_BY_PROVIDER,
        transitions.REVIEW_2_BY_PROVIDER,
        transitions.REVIEW_1_BY_CUSTOMER,
        transitions.REVIEW_2_BY_CUSTOMER,
        transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD,
        transitions.EXPIRE_PROVIDER_REVIEW_PERIOD,
        transitions.EXPIRE_REVIEW_PERIOD,
      ]
    default:
      break;
  }
}


export const updatePrivilegedTransaction = (params) => (dispatch, getstate, sdk) => {

  dispatch(updatePrivilegedTransactionRequest());

  return updatePrivileged(params).then(res => {
    let apiQueryParams = {
      lastTransitions: params.transition === 'transition/editinvoice' ? [transitions.COMPLETE, transitions.ADMIN_COMPLETE, transitions.ADMIN_COMPLETE, transitions.SENDINVOICE, transitions.CONFIRM_PAYMENT, transitions.EDIT_INVOICE] : [transitions.COMPLETE, transitions.ADMIN_COMPLETE],
      include: [
        'listing',
        'provider',
        'provider.profileImage',
        'customer',
        'customer.profileImage',
        'booking',
        'email',
        'images'
      ],
      'fields.transaction': [
        'processName',
        'lastTransition',
        'lastTransitionedAt',
        'transitions',
        'payinTotal',
        'payoutTotal',
        'lineItems',
        'protectedData'
      ],
      'fields.listing': ['title', 'availabilityPlan', 'publicData.listingType'],
      'fields.user': ['email', 'protectedData.phoneNumber', 'profile.displayName', 'profile.abbreviatedName'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
      perPage: INBOX_PAGE_SIZE,
    };
    return sdk.transactions
      .query(apiQueryParams)
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(fetchOrdersOrSalesSuccess(response));
        return response;
      })
      .catch(e => {
        dispatch(fetchOrdersOrSalesError(storableError(e)));
        throw e;
      })

  }).catch(e => {
    dispatch(updatePrivilegedTransactionError(storableError(e)));
    throw e;
  });
}

export const getOwnlistings = (params) => (dispatch, getstate, sdk) => {

  dispatch(getOwnListingsRequest());

  let apiQueryParams = {
    include: ['images'],
    'fields.image': [
      'variants.scaled-small',
      'variants.square-small2x',
      'variants.scaled-medium',
      `variants.listing-card`,
      `variants.listing-card-2x`,
    ],
  };

  return sdk.ownListings.query(apiQueryParams)
    .then((response) => {
      // Handle the response containing the listings
      const listings = response.data;
      return listings;

    }).catch(e => {
      dispatch(getOwnListingsError(storableError(e)));
      throw e;
    });
}

export const updateTransactionMetadata = (transactionId, txRole, lastTransition, currentUserId) => (dispatch, getState, sdk) => {
  dispatch(updateMetadataRequest());

  return updateSeenMetadata({ transactionId: transactionId, userRole: txRole, lastTransition: lastTransition }).
    then(res => {

    })
}


export const sendBookingInvite = (data) => (dispatch, getState, sdk) => {
  dispatch(sendBookingInviteRequest()); 

  return inviteBookingLink(data).then(res => {    
    const link = res;
    dispatch(sendBookingInviteSuccess(link));
    }).catch(e => {      
      dispatch(sendBookingInviteError());
      throw e;
    });
}
